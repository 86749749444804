<template>
  <app-form
    class="m-auto w-full max-w-[1040px] grid-cols-2 items-center gap-x-16 gap-y-2 md:grid"
    @submit="$emit('submit')"
  >
    <div
      class="col-span-2 mb-4 md:mb-0"
      :class="{ invisible: !displayBackCta }"
    >
      <app-button-back
        data-cy-wizard-back
        @click="utilities?.send({ type: 'back' })"
      />
    </div>

    <div
      class="hidden aspect-square place-content-center rounded-lg bg-primary-50 p-8 md:grid"
    >
      <slot name="aside">
        <app-pop-transition mode="out-in">
          <app-image
            v-if="cover"
            :key="cover"
            alt=""
            class="max-h-[280px] max-w-[300px]"
            :src="cover"
          />
        </app-pop-transition>
      </slot>
    </div>

    <div>
      <div class="balance mb-8">
        <h2 class="text-2xl font-bold">{{ title }}</h2>
        <p v-if="subtitle || $slots.subtitle" class="mt-2 text-subtle">
          <slot name="subtitle">
            {{ subtitle }}
          </slot>
        </p>
      </div>

      <div>
        <slot />
      </div>

      <slot name="cta">
        <app-button
          block
          class="mt-6"
          color="dark"
          data-cy-wizard-step-cta
          :disabled="disabled"
          size="lg"
          type="submit"
          >{{ ctaLabel ?? $t("app.cta.continue") }}</app-button
        >
      </slot>

      <div v-if="$slots.append" class="mt-8">
        <slot name="append" />
      </div>
    </div>
  </app-form>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "~/core/i18n/i18n.model";

defineProps<{
  cover?: string;
  title: I18nMessage;
  subtitle?: I18nMessage;
  ctaLabel?: I18nMessage;
  disabled?: boolean;
}>();

defineEmits<{
  submit: [];
}>();

const utilities = useWizardUtilities();

const displayBackCta = computed(() => {
  return !!utilities?.state.value?.can({ type: "back" });
});
</script>
